import React, { Component } from "react";
import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { createBrowserHistory } from "history";
import ReactLoading from "react-loading";

const loading = () => (
  <div className="animated fadeIn pt-3" style={{marginLeft:'50%',marginTop:'20%'}}>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const ForgotPassword = React.lazy(() => import("./views/Pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/Pages/ResetPassword"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const hist = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/resetPassword" name="Reset Password" render={props => <ResetPassword {...props} />} />
            <Route exact path="/forgotPassword" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
            <Route exact path="/registration" name="Register" render={props => <Register {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout history={hist} {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
